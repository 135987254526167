import { styled, useTheme } from '@mui/material'

const StyledImg = styled('img')(({ theme }) => ({
  height: 'auto',
  margin: 0,
  [theme.breakpoints.up('xs')]: {
    width: `${theme.logo.logoSize - 50}px`,
  },
  [theme.breakpoints.up('md')]: {
    width: `${theme.logo.logoSize}px`,
  },
  [theme.breakpoints.up('xl')]: {
    width: `${theme.logo.logoSize + 50}px`,
  },
}))

export function Logo() {
  const theme = useTheme()
  return <StyledImg src={theme.logo.url} />
}
