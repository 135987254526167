import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DownloadIcon from '@mui/icons-material/Download'
import { FC } from 'react'

import { FileOC } from '../interfaces/api'
import { colors } from '../theming/colors'

interface FileItemProps {
  file: FileOC
  onClick: () => void
}

export const FileItem: FC<FileItemProps> = ({ file, onClick }) => {
  return (
    <ListItem
      key={file.FileName}
      sx={{ backgroundColor: colors.WHITE }}
      secondaryAction={
        <IconButton
          edge="end"
          onClick={onClick}
          sx={{ '& svg': { path: { fill: colors.BLACK } } }}
        >
          <DownloadIcon />
        </IconButton>
      }
    >
      <ListItemText primary={file.FileName} />
    </ListItem>
  )
}
